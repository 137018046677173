
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { NavigationBar } from './components/navigation/navigationBar';
import { EventPageContainer } from './components/pages/events/eventPageContainer';
import { ExportEventsPage } from './components/pages/exportEvents/exportEventsPage';
import { HelpPage } from './components/pages/help/helpPage';
import { LiveStatusPage } from './components/pages/liveStatus/liveStatusPage';
import { ScheduledEventsPage } from './components/pages/scheduleEvents/scheduledEventsPage';
import { MonitoringGroupsPage } from './components/pages/settings/monitoringGroups/monitoringGroupsPage';
import { PPAEventsPage } from './components/pages/settings/ppaEvents/ppaEventsPage';
import { UserSettingsPage } from './components/pages/settings/userSettings/userSettingsPage';
import { theme } from './styles/theme';

const useStyles = makeStyles({
    pageRoot: {
        backgroundColor: theme.palette.background.paper,
        margin: '2.5rem',
        height: '100%',
    },
});

const App = () => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* MUI helper to standardize the CSS for the application */}
                <CssBaseline />
                <BrowserRouter>
                    <NavigationBar />
                    <div className={classes.pageRoot}>
                        <Switch>
                            <Route path="/legacyLiveStatus">
                                <LiveStatusPage />
                            </Route>
                            <Route path="/events">
                                <EventPageContainer />
                            </Route>
                            <Route path="/scheduleEvents">
                                <ScheduledEventsPage />
                            </Route>
                            <Route path="/exportEvents">
                                <ExportEventsPage />
                            </Route>
                            <Route path="/settings/ppa">
                                <PPAEventsPage />
                            </Route>
                            <Route path="/settings/monitoring">
                                <MonitoringGroupsPage />
                            </Route>
                            <Route path="/settings/user">
                                <UserSettingsPage />
                            </Route>
                            <Route path="/help">
                                <HelpPage />
                            </Route>
                            <Redirect exact from="/settings" to="/settings/monitoring" />
                            <Redirect from="/" to="/events" />
                        </Switch>
                    </div>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
